import React from "react"
import ReactDOM from "react-dom"

import App from "./App"
import "./style/style.scss"
ReactDOM.render(
    <React.StrictMode>
            <App />
    </React.StrictMode>,
    document.getElementById("app")
)
